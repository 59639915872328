import Inputmask from "inputmask/dist/jquery.inputmask";
import { initializeAccessibility } from "./utilities/a11y";

$(function () {
    ValidationSetupMaskedItems();
    InitPageEvents();
    ValidationSupplemental();
    HasSectionInUrl();
    initializeAccessibility();
});

function ValidationSetupMaskedItems() {
    // override default
    Inputmask.extendDefaults({
        placeholder: ""
    });

    // Add masks
    ValidationApplyMasks();

}

function InitPageEvents() {
    if ($(".content__form-zipcode").length) {
        $('.content__form-zipcode').on('submit', function (e) {
            var $t = $(this);
            if ($t.valid()) {
                dataLayer.push({ "event": "ZipCodeFormSubmit", "Zip": $t.find('[name="zipCode"]').val(), "State": $t.find('[name="State"]').val() });
            }
        });
    }

    var stepProgressBar = $('.js--step-progress-bar');
    window.addEventListener('scroll', function () {

        var scrollPos = $(this).scrollTop();
        var prefixCssClass = "step-progress-bar--";

        if (scrollPos > 25) {
            stepProgressBar.addClass(prefixCssClass + "scroll-down-effect");
            stepProgressBar.removeClass(prefixCssClass + "scroll-up-effect");
        } else {
            stepProgressBar.addClass(prefixCssClass + "scroll-up-effect");
            stepProgressBar.removeClass(prefixCssClass + "scroll-down-effect");
        }

    });

}

function ValidationSupplemental() {
    $("#FullForm").submit(function (e) {
        var $form = $(this).closest('form');
        var zipcode = $form.find('[name="Zipcode"]').val();
        var state = $("#State").val();

        // Add inLineFormSubmission event
        if ($('.field-validation-error').length === 0) {
            dataLayer.push({ "event": "inLineFormSubmission", "Zip": zipcode, "State": state });
        }

        // Add ValidLeadSubmission event
        if (IsFormFilled()) {
            dataLayer.push({ "event": "ValidLeadSubmission", "Zip": zipcode, "State": state });
        }
    });
}

function ValidationApplyMasks() {
    /// <summary>Applies masks to elements with given class names.</summary>
    /// <note>This function is seperated by design to allow post-instantiation application of masks. Depends on ValidationSetupMaskedItems() also being called to handle events.</note>

    $('.mask-date').inputmask({
        alias: "mm/dd/yyyy",
        placeholder: "_",
        clearMaskOnLostFocus: true
    });

    $(".mask-phone").inputmask({
        mask: ["(999) 999-9999"],
        removeMaskOnSubmit: true,
        clearMaskOnLostFocus: false,
        autoUnmask: true,
        placeholder: "_"
    });
    $('.mask-zip').inputmask({
        regex: '^\\d{5}$'
    });
}

function IsFormFilled() {
    var fname = $("#FirstName").val();
    var lname = $("#LastName").val();
    var email = $("#Email").val();
    var phone = $("#Phone").val();

    return fname !== "" & lname !== "" & email !== "" && phone !== "" ? true : false;
}

function HasSectionInUrl() {
    if (window.location.hash) {
        GoToSection(window.location.hash)
    }
}

function GoToSection(section, speed = 0) {
    let $root = $('html, body');
    const headerHeight = 65

    $root.animate({
        scrollTop: $(section).offset().top - headerHeight
    }, speed);
}

if ($(".reference-page").length > 0) {
    
    $("a[href*='#']").each(function (i, e) {
        $(e).click(function () {
            GoToSection($.attr(this, 'href'), 'slow')
            return false;
        });
    });
}

export {ValidationApplyMasks}