import "jquery-validation";
import "jquery-validation-unobtrusive"

$(function () {

    $(".content__form-zipcode").each(function () {
        var form = $(this);

        form.validate({
            rules: {
                zipCode: {
                    required: true,
                    minlength: 5,
                    validZip: true
                }
            },
            messages: {
                zipCode: {
                    required: "We need your ZIP code to find plans in your area.",
                    minlength: "We need your ZIP code to find plans in your area.",
                    validZip: "We need your ZIP code to find plans in your area."
                }
            },
            errorElement: "em",
            errorPlacement: function (error, element) {
                error.addClass("zip-code-error");
                if (element.next !== null) {
                    if (!element.next("span")[0]) {
                        $("<span><img src='/assets/images/error.svg' alt='Error'/></span>").insertBefore(element);
                    }
                }
                if (element.prop("type") === "checkbox") {
                    error.insertAfter(element.parent("label"));
                } else {
                    error.insertBefore(element);
                }
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parent().addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parent().addClass("has-success").removeClass("has-error");
            }
        });

        var isValidZipcode = false;
        var currentZipcode = 0;
        jQuery.validator.addMethod("validZip", function (value, element) {
            var _requestToken = element.form.__RequestVerificationToken.value;
            if (value === currentZipcode) return isValidZipcode;
            currentZipcode = value;
            isValidZipcode = isValidApi(value, _requestToken, element.form);
            return isValidZipcode;
        }, "We need your ZIP code to find plans in your area.");

    });
});

const isValidApi = function (zipcode, rvt, form) {
    if (zipcode.length < 5) {
        return false;
    }

    var isValid = false;
    IsValidZipCode(zipcode, rvt).done(function (data) {
        form.State.value = data.Region;
        
        isValid = !data.HasError;
        return isValid;
    });
    
    return isValid;
}

const IsValidZipCode = function(zipcode, rvt) {
    return $.ajax({
        url: '/umbraco/surface/ZipcodeFormSurface/ValidZipCode',
        data: { '__RequestVerificationToken': rvt, 'zipCode': zipcode },
        method: 'POST',
        timeout: 10000,
        async: false
    });
}

export { IsValidZipCode, isValidApi }