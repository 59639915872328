String.prototype.trimLeft = function () {
    var trimLeft = /^\s+/;
    return this.replace(trimLeft, "");
};

String.prototype.trimRight = function (charlist) {
    var trimRight = /\s+$/;
    return this.replace(trimRight, "");
};

String.prototype.trim = function(charlist) {
    return this.trimLeft(charlist).trimRight(charlist);
};

String.prototype.trim = function (charlist) {
    return this.trimLeft(charlist).trimRight(charlist);
};

String.prototype.properName = function (charlist) {
    var stringProperName = this.replace(/\s+/g, ' ');
    return stringProperName.trim(charlist);
}